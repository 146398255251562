.warehouse-popup .form-field {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.warehouse-popup .form-field > div:first-child {
  min-width: 150px;
  padding-left: 30px;
  font-weight: 500;
}

.warehouse-popup .save-button {
  max-width: 100px;
  display: flex;
  padding: 30px;
}

.warehouse-popup .MuiFormControl-root {
  width: 350px;
}

@media only screen and (max-width: 600px) {
  .warehouse-popup .MuiFormControl-root {
    box-sizing: border-box;
    width: 100%;
  }
  .warehouse-popup .form-field {
    flex-direction: column;
    align-items: flex-start;
    padding: 3px 10px;
  }
  .warehouse-popup .form-field > div:first-child {
    padding-left: unset;
  }
}
