.shipping-container {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.shipping-cost__main-list {
  display: flex;
}
.shipping-cost__main-list ul {
  margin-left: 30px;
  padding: 0;
}

.shipping-cost__main-list ul li {
  padding-bottom: 5px;
}

.shipping-cost__bedge-list {
  list-style-type: none;
}
.cost-table__input {
  margin-left: auto;
  width: 110px;
}

.cost-table__row > td {
  padding: 5px;
}

.cost-table__head-row th {
  width: 50px;
  text-align: left;
}
