.grecaptcha-badge {
  visibility: hidden;
}

.container {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0px 20px 0px;
}

@media only screen and (max-width: 600px) {
  .makeStyles-container-4 {
    padding: 0px !important;
  }
  .makeStyles-content-3 {
    padding: 30px 10px !important;
  }
}

.loading {
  display: flex;
  justify-content: center;
}

.details {
  background: white;
  margin-bottom: 35px;

  .title {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 30px;
    border-bottom: 1px solid lightgrey;
    font-weight: 500;
    font-size: 27px;
    line-height: 1.23;
  }

  .save {
    padding: 0px 30px 30px 30px;
  }

  .table {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    padding: 30px;
    @media (max-width: "1024px") {
      padding: 10px;
    }
  }

  .card {
    display: flex;

    @media (max-width: "1024px") {
      flex-direction: column;
    }
  }
  .col {
    display: flex;
    flex-direction: column;
    padding: 30px;
    flex-grow: 1;

    @media (max-width: "1024px") {
      padding: 10px;
    }
  }
  .row {
    display: flex;
    margin: 11px 0px 11px 0px;

    @media (max-width: "1024px") {
      flex-direction: column;
    }
  }
  .label {
    font-weight: 500;
    min-width: 180px;

    @media (max-width: "1024px") {
      padding-bottom: 10px;
    }
  }
  .form-input {
    max-width: 350px;
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }
  }
}
